<template>
	<div>
		<v-toolbar flat dark color="primary" class="sticky-top" height="80">
			<v-toolbar-title> {{ $t('SalesHistory.Mijn_bestelhistoriek') }} </v-toolbar-title>
			<v-toolbar-items class="kmp">
				<v-tabs v-model="view" center background-color="primary" hide-slider class="my-auto"
					@change="onChangeView()">
					<v-tab>{{ $t('SalesHistory.Besteladvies') }}</v-tab>
					<v-tab>{{ $t('SalesHistory.Meest_gekocht') }}</v-tab>
					<v-tab>{{ $t('SalesHistory.Alles') }}</v-tab>
				</v-tabs>
			</v-toolbar-items>
		</v-toolbar>

		<v-container fluid>
			<v-row justify="center" v-for="salesItem of salesYtdView" :key="salesItem.productCode">
				<v-col cols="12">
					<sales-history-card :product="salesItem" />
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import caas from "@/services/caas";
import SalesHistoryCard from "@/components/SalesHistoryCard"
import { mapGetters } from "vuex";
export default {
	name: "SalesHistory",
	components: { SalesHistoryCard },

	data() {
		return {
			loading: false,
			salesYtd: [],
			salesYtdView: [],
			atpCode: "",
			atpQty: 0,
			view: 0
		};
	},
	mounted() {
		this.getSalesYtd().then(() => this.onChangeView())
	},
	computed: {
		...mapGetters({
			locale: "session/locale"
		}),
	},
	methods: {
		getSalesYtd() {
			this.loading = true;
			return caas
				.rpc("getPortalUserSalesYtd", { languageCode: this.locale })
				.then((response) => {
					if (response.data.success.data) {
						this.salesYtd = response.data.success.data.salesYtd;
					} else if (response.data.success.error) {
						throw response.data.success.error;
					}
					this.loading = false;
				})
				.catch((error) => {
					console.log(error.message);
					this.loading = false;
				})
		},
		onChangeView() {
			switch (this.view) {
				case 0:
					this.salesYtd.sort((a, b) => b.qtyToOrder - a.qtyToOrder);
					this.salesYtdView = this.salesYtd.filter(i => i.qtyToOrder > 0)
					break
				case 1:
					this.salesYtd.sort((a, b) => b.qtyPrevYear - a.qtyPrevYear);
					this.salesYtdView = this.salesYtd.slice(0, 10);
					break
				default:
					this.salesYtdView = this.salesYtd
			}
			this.$vuetify.goTo(0)
		}
	},
};
</script>