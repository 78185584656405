<template>
    <v-card outlined rounded max-width="900" class="mx-auto">
        <v-card-title>
            {{ this.product.productName }}
        </v-card-title>
        <v-card-subtitle>{{ product.productCode }}</v-card-subtitle>
        <v-divider />
        <v-card-text>
            <v-row align="center" justify="space-between">
                <v-col cols="3">
                    <v-img v-if="product.imageUrl" :src="product.imageUrl" height="125" contain
                        position="center center" />
                    <v-img v-else src="@/assets/imgnotfound.jpg" height="125" contain position="center center" />
                </v-col>
                <v-col cols="9">
                    <v-row>
                        <v-col>
                            <v-sheet outlined>
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    {{ $t('SalesHistoryCard.Bestelhistoriek') }}
                                                </th>
                                                <th class="text-center">
                                                    {{ $t('SalesHistoryCard.Verleden_jaar') }}
                                                </th>
                                                <th class="text-center">
                                                    {{ $t('SalesHistoryCard.Dit_jaar') }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{{ $t('SalesHistoryCard.Year_to_date') }}</td>
                                                <td class="text-center">{{ product.qtyPrevYtd }}</td>
                                                <td class="text-center">{{ product.qtyThisYtd }}</td>
                                            </tr>
                                            <tr>
                                                <td>{{ $t('SalesHistoryCard.Totaal') }}</td>
                                                <td class="text-center">{{ product.qtyPrevYear }}</td>
                                                <td class="text-center">{{ product.qtyThisYear }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-sheet>
                        </v-col>
                    </v-row>
                    <v-row align="center" v-if="product.isActive">
                        <v-col>
                            <v-text-field :label="$t('SalesHistoryCard.Eenheidsprijs')"
                                :value="product.unitPrice ? $n(product.unitPrice, 'decimal') + ' ' + product.currencyCode : $t('SalesHistoryCard.op_aanvraag')"
                                readonly hide-details flat class="no-underline" />
                        </v-col>
                        <v-col>
                            <v-text-field v-show="product.qtyToOrder" :label="$t('SalesHistoryCard.Besteladvies')"
                                :value="product.qtyToOrder" readonly hide-details flat class="no-underline" />
                        </v-col>
                        <v-spacer />
                        <v-col cols="auto">
                            <cart-item-toolbar :productCode="product.productCode"
                                :minimalOrderQty="product.minimalOrderQty" />
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col align="center">{{ $t('SalesHistoryCard.Dit_product_is_niet_meer_verkrijgbaar') }}</v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import CartItemToolbar from "./CartItemToolbar.vue";
export default {
    name: "SalesHistoryCard",
    components: { CartItemToolbar },
    props: {
        product: Object,
    },
}
</script>
